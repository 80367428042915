import { EmailRecipientGroup } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { getAccountIdsFromEmailRecipientGroups } from "./helpers/getAccountIdsFromEmailRecipientGroups";

export async function email__server__getNumRecipients(p: { selfAccountId: string; emailGroups: EmailRecipientGroup[] }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const senderAccount = await h.Account.getDoc(p.selfAccountId);

  if (!senderAccount) {
    throw new Error("User account not found");
  }

  return (
    await getAccountIdsFromEmailRecipientGroups({
      recipientGroups: p.emailGroups,
      senderAccountId: p.selfAccountId
    })
  ).length;
  // SERVER_ONLY_TOGGLE
}

email__server__getNumRecipients.auth = (req: any) => {
  return validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
