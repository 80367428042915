import {
  CommunicationTag,
  EmailAttachment,
  EmailRecipientGroup,
  LowPriorityNotificationDetailType,
  NotificationType,
  PushNotificationSettingToRespect
} from "@ollie-sports/models";
import axios from "axios";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { isProduction } from "../../utils/env-helpers";
import { isDeployedCode } from "../../utils/server-helpers";
import { getAddressesFromEmailRecipientGroups } from "./helpers/getAddressesFromEmailRecipientGroups";
import _ from "lodash";
import { isDevelopmentEnv } from "../../internal-utils/misc";
import { shouldSendEmail } from "../../utils";
import { generateAndProcessNotifications } from "../notification/notification.plumbing";
import { translate } from "@ollie-sports/i18n";
import { importantNonOrgEmailNotificationText, importantOrgEmailNotificationText } from "../../constants";

export async function email__server__send(p: {
  selfAccountId: string;
  subject: string;
  messageHTML: string;
  recipientGroups: EmailRecipientGroup[];
  shouldSendCopyToSelf: boolean;
  attachments?: (EmailAttachment & { base64: string })[];
  isEmailImportant: boolean;
  sendFromOrgIdContext?: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const {
    injectedServerLibraries: { sendGrid }
  } = getServerHelpers();

  const senderAccount = await h.Account.getDoc(p.selfAccountId);

  if (!senderAccount) {
    throw new Error("User account not found");
  }

  const recipients = await getAddressesFromEmailRecipientGroups({
    recipientGroups: p.recipientGroups,
    senderAccountId: p.selfAccountId
  });

  let toInfo: { email: string; accountId?: string; name?: string }[] = recipients.map(r => ({
    email: r.email,
    name: r.fullName,
    accountId: r.accountId
  }));

  if (p.shouldSendCopyToSelf && !toInfo.find(a => a.email.toLowerCase() === senderAccount.email.toLowerCase())) {
    toInfo.push({ email: senderAccount.email, name: `${senderAccount.firstName} ${senderAccount.lastName}` });
  }

  const emailId = h.Email.generateId();

  await h.Email.add({
    doc: {
      id: emailId,
      createdAtMS: Date.now(),
      senderAccountId: p.selfAccountId,
      messageHTML: p.messageHTML,
      recipientGroups: arrToObj(p.recipientGroups),
      numberEmailsSent: recipients.length,
      attachments: p.attachments ? arrToObj(p.attachments.map(a => _.omit(a, "base64"))) : undefined,
      status: "sent",
      subject: p.subject
    }
  });

  const attachmentData = p.attachments?.map(a => {
    const indexOfComma = a.base64.indexOf(",");
    const content = indexOfComma > 0 ? a.base64.slice(indexOfComma + 1) : a.base64;
    return { content, filename: a.name, type: a.type };
  });

  const toInfoFiltered = isProduction() ? toInfo : toInfo.filter(info => shouldSendEmail(info.email));

  const sendFromOrgContext = p.sendFromOrgIdContext ? await h.Org.getDoc(p.sendFromOrgIdContext) : null;

  const invocationId = Math.random() + "" + Math.random();

  if (sendFromOrgContext && !sendFromOrgContext.accounts[p.selfAccountId]) {
    throw new Error("Unable to send email from org context!");
  }

  const senderName = `${senderAccount.firstName.trim()} ${senderAccount.lastName.trim()}`;

  if (toInfoFiltered.length > 0) {
    const chunks = _.chunk(toInfoFiltered, 500);

    try {
      await Promise.all(
        chunks.map(chunk => {
          return sendGrid.sendMultiple({
            category: emailId,
            to: chunk,
            subject: p.subject,
            trackingSettings: {
              clickTracking: { enable: false },
              ganalytics: { enable: false },
              openTracking: { enable: false },
              subscriptionTracking: { enable: false }
            },
            from: `${senderAccount.firstName} ${senderAccount.lastName} <noreply@olliesports.com>`,
            html: p.messageHTML,
            replyTo: senderAccount.email,
            attachments: attachmentData,
            mailSettings: {
              bypassListManagement: {
                enable: true
              }
            }
          });
        })
      );

      olliePipe.emitEvent({ type: `analytic-email-sent`, payload: { toInfoFiltered } });

      if (p.isEmailImportant) {
        await generateAndProcessNotifications({
          data: toInfoFiltered.filter((a): a is { email: string; accountId: string } => !!a.accountId),
          generateFn: async a => {
            const { communicationLocale: serverLocale } = a.accountPrivate;
            const msg = sendFromOrgContext
              ? { ...importantOrgEmailNotificationText, serverLocale }
              : { ...importantNonOrgEmailNotificationText, serverLocale };

            const body = translate(msg, { orgName: sendFromOrgContext?.name || "", userName: senderName });

            const title = translate(
              { defaultMessage: "{entityName} - Important Email", serverLocale },
              { entityName: sendFromOrgContext?.shortName || senderName }
            );

            const expireAtMS = Date.now() + 1000 * 60 * 60 * 24 * 3;

            return {
              notificationType: NotificationType.lowPriorityNotification,
              idempotencyKey: a.accountPrivate.id + invocationId,
              lowPriorityNotificationDetail: {
                body,
                title,
                type: LowPriorityNotificationDetailType.importantEmail,
                expireAtMS,
                routerPath: ""
              },
              realTimeNotification: {
                e: expireAtMS
              },
              pushNotificationData: {
                title,
                body,
                lowPriorityNotificationDetailType: LowPriorityNotificationDetailType.importantEmail,
                pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND
              },
              type: "lowPriorityNotification"
            };
          }
        });

        olliePipe.emitEvent({ type: `analytic-important-email-notification-sent`, payload: { toInfoFiltered } });
      }
    } catch (e) {
      olliePipe.emitEvent({
        type: "error-sending-email",
        payload: {
          error: e,
          attachmentDataAfter: attachmentData,
          attachmentDataBefore: p.attachments
        }
      });
      await h.Email.delete({ id: emailId });
      throw e;
    }
  }
  // SERVER_ONLY_TOGGLE
}

email__server__send.auth = (req: any) => {
  return validateTokenAndEnsureSelfAccountIdMatches(req);
};

function arrToObj<T>(arr: T[]) {
  return arr.reduce((acc, g, i) => {
    acc[i] = g;
    return acc;
  }, {} as Record<string, T>);
}

// i18n certified - complete
