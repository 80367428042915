import { Account, CommunicationTag, EmailRecipientGroup } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../../helpers";
import _ from "lodash";
import { getAccountIdsFromEmailRecipientGroups } from "./getAccountIdsFromEmailRecipientGroups";

export async function getAddressesFromEmailRecipientGroups(p: {
  recipientGroups: EmailRecipientGroup[];
  senderAccountId: string;
}): Promise<{ email: string; accountId?: string; fullName?: string }[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const accountIds = await getAccountIdsFromEmailRecipientGroups(p);

  const accounts = (await h.Account.getDocs(accountIds)).filter((a): a is Account => !!a);

  const rawEmails = p.recipientGroups.map(g => g.type === "rawEmail" && g.email).filter((a): a is string => !!a);

  const emails = _(accounts)
    .map(a => {
      const val: { email: string; accountId?: string; fullName?: string } = {
        email: a.email,
        accountId: a.id,
        fullName: `${a.firstName} ${a.lastName}`
      };
      return val;
    })
    .concat(rawEmails.map(e => ({ email: e })))
    .uniqBy(a => a.email.toLowerCase())
    .sortBy(a => a.email.toLowerCase())
    .value();

  return emails;
}

// i18n certified - complete
