import { Email } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { getSubscriptionWithPaginatedFetchMore, PaginatedSubscriptionReturnType } from "../../internal-utils/bifrostHelpers";

export function email__client__byAccountSubscription(p: { userAccountId: string }): PaginatedSubscriptionReturnType<Email> {
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  return getSubscriptionWithPaginatedFetchMore({
    model: h.Email,
    baseFilterCriteria: [{ senderAccountId: ["==", p.userAccountId] }],
    pageSize: 100,
    onError: err => {
      olliePipe.emitEvent({
        type: "error-fetching-more-email-subscription",
        payload: { userAccountId: p.userAccountId, error: err }
      });
    }
  });
}

// i18n certified - complete
