import { CommunicationTag, Team, Team__StaffTypes } from "@ollie-sports/models";
import _ from "lodash";
import { getAllPossibleTags } from "../../compute/communicationTag/getAllPossibleTags.compute";
import { getUserTeamContextInfo } from "../../compute/communicationTag/getUserTeamContextInfo";

export async function email__server__getAllPossibleTags(p: { userAccountId: string }): Promise<CommunicationTag[]> {
  // SERVER_ONLY_TOGGLE
  const userTeamContextInfo = await getUserTeamContextInfo(p.userAccountId);

  return await getAllPossibleTags({
    ...userTeamContextInfo,
    userAccountId: p.userAccountId
  });
  // SERVER_ONLY_TOGGLE
}

email__server__getAllPossibleTags.auth = (req: any) => {
  // return validateCanAccessUserInfo({ propertyToCheck: "userAccountId", request: req });
};

// i18n certified - complete
