import { Account, CommunicationTag, EmailRecipientGroup, PlayerBundle__AccountType } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../../helpers";
import _ from "lodash";
import { getAccountIdsMatchingTag } from "../../../compute/communicationTag/getAccountIdsMatchingTag.compute";

export async function getAccountIdsFromEmailRecipientGroups(p: {
  recipientGroups: EmailRecipientGroup[];
  senderAccountId: string;
}): Promise<string[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const rawAccountIds = await Promise.all(
    p.recipientGroups.map(async (group): Promise<string[]> => {
      if (group.type === "rawEmail") {
        return [];
      } else if (group.type === "account") {
        return [group.accountId];
      } else if (group.type === "arbitraryPlayerBundleGrouping") {
        return fetchManagingGuardianAccountIds(group.playerBundleIds);
      } else if (group.type === "arbitraryAccountOrPlayerBundleGrouping") {
        const [playerBundleIds, accountIds] = _.partition(group.accountIdsOrPlayerBundleIds, a => a.startsWith("playerBundle-"));
        const guardianAccountIds = await fetchManagingGuardianAccountIds(playerBundleIds);

        return _.uniq([...accountIds, ...guardianAccountIds]);
      } else {
        return getAccountIdsMatchingTag({
          tag: group.tag,
          userAccountId: p.senderAccountId,
          getOrgById: orgId => h.Org.getDoc(orgId),
          getTeamById: teamId => h.Team.getDoc(teamId),
          getTeamsByOrgId: orgId =>
            h.Team.query({ where: [{ orgId: ["==", orgId] }, { deletedAtMS: ["==", 0] }] }).then(a => a.docs)
        });
      }
    })
  );

  const shouldFilterSender = !p.recipientGroups.find(g => g.type === "account" && g.accountId === p.senderAccountId);

  return _(rawAccountIds)
    .flatten()
    .filter(a => (shouldFilterSender ? a !== p.senderAccountId : true))
    .uniq()
    .sort()
    .value();
}

function fetchManagingGuardianAccountIds(playerBundleIds: string[]) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return Promise.all(
    playerBundleIds.map(async pbid => {
      const pb = await h.PlayerBundle.getDoc(pbid);
      const hasGuardian = Object.keys(pb?.managingAccounts || {}).find(accId => {
        return pb?.managingAccounts?.[accId]?.type === PlayerBundle__AccountType.guardian;
      });

      return Object.keys(pb?.managingAccounts || {}).filter(accId => {
        return hasGuardian ? pb?.managingAccounts?.[accId]?.type === PlayerBundle__AccountType.guardian : true;
      });
    })
  ).then(a => _.flatten(a));
}

// i18n certified - complete
