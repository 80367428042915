import { CommunicationTag, EmailRecipientGroup } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { getAddressesFromEmailRecipientGroups } from "./helpers/getAddressesFromEmailRecipientGroups";

export async function email__server__getRecipients(p: { selfAccountId: string; emailGroups: EmailRecipientGroup[] }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const senderAccount = await h.Account.getDoc(p.selfAccountId);

  if (!senderAccount) {
    throw new Error("User account not found");
  }

  const r = await getAddressesFromEmailRecipientGroups({
    recipientGroups: p.emailGroups,
    senderAccountId: p.selfAccountId
  });
  return r;
  // SERVER_ONLY_TOGGLE
}

email__server__getRecipients.auth = (req: any) => {
  return validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
